import React from 'react'
import SEO from 'components/SEO/SEO'
import {
  ContentSection,
  ContentTitle,
  FieldSection,
  FieldTitle,
  FieldParagraph,
} from 'components/StyledComponents/StyledComponents'

const EthicsStatementPage = () => (
  <>
    <SEO title="Ethics statement" />
    <ContentSection>
      <ContentTitle>Ethics statement</ContentTitle>
      <FieldSection>
        <FieldParagraph>
          RAD obliges the authors, reviewers and editors to follow ethical
          standards that lead to keeping high scientific level of the journal.
        </FieldParagraph>
      </FieldSection>
      <FieldSection>
        <FieldTitle>Publication ethics for authors</FieldTitle>
        <FieldTitle inside>Authorship of the paper</FieldTitle>
        <FieldParagraph>
          All persons named as authors or co-authors should have made a
          significant contribution to the paper preparation, having a meaningful
          input in the elaboration of the paper at all the stages of the
          reported study.
        </FieldParagraph>
        <FieldParagraph>
          Other persons who participated in certain tasks related to the
          preparation of the paper can be mentioned as collaborators in the
          acknowledgements section. An author submitting a paper should ensure
          that all the co-authors approve of the final version of the paper.
        </FieldParagraph>
        <FieldParagraph>
          Ghost-writing and guest authorship are a manifestation of scientific
          misconduct, are unethical and illegal. Identified ghost-writing and
          guest authorship will be communicated by the editorial office parent
          institutions of the authors and alleged authors.
        </FieldParagraph>
        <FieldTitle inside>Research presentation standards</FieldTitle>
        <FieldParagraph>
          Authors should reliably describe the performed research study and
          objectively and accurately interpret results. Data should be presented
          in sufficient reference and detail to allow for replication of the
          research. Knowingly inaccurate or unreliable presentation and
          interpretation of data and research results constitute unethical and
          illegal conduct. The authors should be ready to provide research data
          for the needs of review for a year after the publication.
        </FieldParagraph>
        <FieldTitle inside>Paper originality</FieldTitle>
        <FieldParagraph>
          The authors ensure that their papers submitted for publication are
          their own original papers and have not been published before, both in
          whole or in vital parts as well as has not been submitted in any other
          research publication. The authors are obliged to properly acknowledge
          the works of others that were used in the paper preparation by citing
          relevant sources.
        </FieldParagraph>
        <FieldParagraph>
          Plagiarism in all forms, including self-plagiarism, is considered
          unacceptable and unethical behaviour and when identified results in
          the paper’s rejection.
        </FieldParagraph>
        <FieldTitle inside>Conflicts of interest</FieldTitle>
        <FieldParagraph>
          In order to prevent any conflict of interests or competing interests,
          the authors are obliged to reveal in their paper all sources of
          project funding, contribution of any institutions, or other reasons
          for conflict of interest, which could have affected the interpretation
          or conclusions of the study.
        </FieldParagraph>
        <FieldTitle inside>Fundamental errors in published papers</FieldTitle>
        <FieldParagraph>
          The authors are obliged to immediately inform the editorial office,
          should they find in their already published papers fundamental errors
          affecting the interpretation and conclusions.
        </FieldParagraph>
      </FieldSection>
      <FieldSection>
        <FieldTitle>Publication ethics for reviewers</FieldTitle>
        <FieldTitle inside>
          Contribution of reviewers to editorial decisions
        </FieldTitle>
        <FieldParagraph>
          The reviewers provide their reviews concerning the papers submitted to
          the journal. Their conclusive opinion can be: approval for
          publication, sending back for corrections or rejection of a paper.
          Opinions on the issue should be univocal.
        </FieldParagraph>
        <FieldParagraph>
          Reviewer is obliged to analyse the scientific soundness of the
          research study and the quality of its presentation in the paper
          reviewed.
        </FieldParagraph>
        <FieldTitle inside>Expertise and promptness</FieldTitle>
        <FieldParagraph>
          Any reviewer feeling unqualified to review a paper on a specific topic
          as well as any reviewer unable to complete the review within a given
          time limit or should decline from reviewing the paper proposed for the
          review.
        </FieldParagraph>
        <FieldTitle inside>Confidentiality</FieldTitle>
        <FieldParagraph>
          Papers received from the editorial office should be treated as a
          confidential document. The papers should not be disclosed to any third
          parties or discussed with them – except for an authorised
          representative of the editorial team. The reviews are anonymous to
          authors and vice versa.
        </FieldParagraph>
        <FieldTitle inside>Preventing conflicts of interest</FieldTitle>
        <FieldParagraph>
          In the case of conflicts of interests reviewers have to immediately
          inform the editorial office about this fact. Moreover, papers received
          for review cannot be used by reviewers, in part or in whole, for
          personal gain, including own research.
        </FieldParagraph>
        <FieldTitle inside>Ethical issues</FieldTitle>
        <FieldParagraph>
          Reviewer is obliged to verify, up to his expertise, any plagiarism or
          similarity to any already published or considered for publishing
          research study.
        </FieldParagraph>
      </FieldSection>
      <FieldSection>
        <FieldTitle>
          Publication ethics for members of the editorial team
        </FieldTitle>
        <FieldTitle inside>Criteria for publication of papers</FieldTitle>
        <FieldParagraph>
          It is the responsibility of the editor-in-chief to take a decision on
          the publication of a given paper in the journal. This decision is
          taken in consultation with the subject editor. The decisions of both
          editors are based on the two reviews of the paper, independent and
          anonymous for the author. When needed, the opinion of the journal’s
          Editorial Board is also taken into account. The ethical issues such as
          ghost-writing, plagiarism and any other breach of the ethics of
          scientific research is vital for the final decision.
        </FieldParagraph>
        <FieldTitle inside>Fair play</FieldTitle>
        <FieldParagraph>
          Papers sent to the editorial office are assessed based on
          content-related criteria and publication ethics. The assessment is not
          affected by any personal factors, including scientific seniority, sex,
          faith, race, origin, nationality or political beliefs of authors.
          Papers are sent for review in order of their submission.
        </FieldParagraph>
        <FieldTitle inside>Confidentiality</FieldTitle>
        <FieldParagraph>
          Members of the editorial team are not allowed to disclose any
          information on the papers submitted for publication to other people
          than – at respective stages of the publication procedure – authors,
          selected reviewers, authorised editors and representatives of the
          publisher.
        </FieldParagraph>
        <FieldTitle inside>
          Preventing conflicts of interest between editorial team members
        </FieldTitle>
        <FieldParagraph>
          Any potential conflict of interest on the side of the editor in
          relation to a given paper submitted for publication should be reported
          to the journal’s Editorial Board and the Board should appoint an
          editor with no conflict of interest for processing a given paper.
        </FieldParagraph>
        <FieldParagraph>
          Unpublished papers cannot be used by members of the editorial team in
          part or in whole without written consent of their authors.
        </FieldParagraph>
      </FieldSection>
    </ContentSection>
  </>
)

export default EthicsStatementPage
